/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'

const About = lazy(() => import('../pages/About'))
const Area = lazy(() => import('../pages/Area'))
const Home = lazy(() => import('../pages/Home/Home'))
//const Plants = lazy(() => import('../pages/Plants'))
const Rain = lazy(() => import('../pages/Rain'))

const routes = [
  {
    path: '/about',
    exact: true,
    element: <About />,
  },
  {
    path: '/area',
    exact: true,
    element: <Area />,
  },
  {
    path: '/home',
    exact: true,
    element: <Home />,
  },
  // {
  //   path: '/plants',
  //   exact: true,
  //   element: <Plants />,
  // },
  {
    path: '/rain',
    exact: true,
    element: <Rain />,
  },
]

export default routes
